<template>
    <div id="main">
        <main :class="isDesktop ? 'main container desktop' : 'main container'">
            <div
                :class="
                    isDesktop
                        ? 'card card-checkout desktop'
                        : 'card card-checkout'
                "
            >
                <div class="py-1 px-3 md:p-5 lg:p-8">
                    <router-view />
                    <div
                        v-if="isDesktop"
                        class="absolute top-5 right-5 cursor-pointer"
                        @click="showAlertClose"
                    >
                        <IconExit color="black" />
                    </div>
                </div>
            </div>
        </main>
        <div v-if="!isDesktop">
            <footer-checkout />
        </div>
    </div>
</template>

<script>
import { CHECKOUT_LAYOUT, MIGRATION_END_VIEW } from "@/router/constants";
import FooterCheckout from "@/components/Checkout/FooterCheckout.vue";
import { GET_STORE, STORE_MODULE } from "@/store/constants/store";
import { mapActions, mapGetters } from "vuex";
import IconExit from "@/components/Icons/IconExit.vue";

export default {
    components: { 
        FooterCheckout,
        IconExit 
    },
    name: CHECKOUT_LAYOUT,
    async mounted() {
        if (this.store?.shopCode == null) {
            await this.requeststoreAction();
        }
    },
    computed: {
        ...mapGetters({
            isDesktop: `isDesktop`
        }),
    },
    methods: {
        ...mapActions({
            requeststoreAction: `${STORE_MODULE}/${GET_STORE}`,
        }),
        showAlertClose() {
            if (this.$route.name === MIGRATION_END_VIEW) {
                return alert("@@finalizarfluxoassinatura");
            }
            return alert("@@fechar");
        },
    },
};
</script>

<style></style>
