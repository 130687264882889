var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "main" } }, [
    _c(
      "main",
      { class: _vm.isDesktop ? "main container desktop" : "main container" },
      [
        _c(
          "div",
          {
            class: _vm.isDesktop
              ? "card card-checkout desktop"
              : "card card-checkout",
          },
          [
            _c(
              "div",
              { staticClass: "py-1 px-3 md:p-5 lg:p-8" },
              [
                _c("router-view"),
                _vm.isDesktop
                  ? _c(
                      "div",
                      {
                        staticClass: "absolute top-5 right-5 cursor-pointer",
                        on: { click: _vm.showAlertClose },
                      },
                      [_c("IconExit", { attrs: { color: "black" } })],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
      ]
    ),
    !_vm.isDesktop ? _c("div", [_c("footer-checkout")], 1) : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }