var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "footer",
    { staticClass: "ext-xs text-center text-gray-400 bg-transparent mt-0" },
    [
      _c("img", {
        staticClass: "w-24 m-auto",
        attrs: {
          src: require("@/assets/images/logo-nextar-gray.svg"),
          alt: "logo-1",
        },
      }),
      _c("p", { domProps: { innerHTML: _vm._s(_vm.$t("footer.message")) } }),
      _vm._m(0),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "leading-10 h-10" }, [
      _vm._v(" site seguro "),
      _c("img", {
        staticClass: "w-24 -mt-1 inline",
        attrs: {
          src: require("@/assets/images/le-logo-wide2.svg"),
          alt: "logo-2",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }